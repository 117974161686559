@import 'styles/sizes';
@import 'styles/keyframes';

/* Single project configuration */

$white: #ffffff;
$black: #000000;

$neutral-50: #f8fafc;
$neutral-100: #f1f5f9;
$neutral-200: #e2e8f0;
$neutral-300: #cbd5e1;
$neutral-400: #94a3b8;
$neutral-500: #64748b;
$neutral-600: #475569;
$neutral-700: #334155;
$neutral-800: #1e293b;
$neutral-900: #00131f;

$primary-50: #ebf5ff;
$primary-100: #d1e8ff;
$primary-200: #cce9fe;
$primary-300: #77caff;
$primary-400: #2fb0ff;
$primary-500: #0490e9;
$primary-550: #0a7cff;
$primary-600: #0077d0;
$primary-700: #005ea8;
$primary-800: #01508b;
$primary-900: #084372;
$primary-950: #052a4c;

$blue: #3fa9f5;
$dark-blue: #124264;
$green: #40d586;
$green-dark: #41d585;
$red: #dc3545;
$dark-red: #6b0505;
$branded-red: #e33333;

$light-gray: #b9b9b9;
$section-gray: #f6f7f9;
$logo: #179f96;
$light-gray-100: #e9ebed;

$primary-blue: #2aa8f8;
$primary-safe-play: #0490e9;
$blue-200: #007ccd;
$gray: #939ca4;
$dark-gray: #192a36;

/* Cross-project configuration */

$main-font: 'Exo';
$main-font-set: 'Exo', sans-serif;
$secondary-font: 'Barlow Condensed';
$tertiary-font: 'Roboto Mono';

$default-transition: 0.2s ease-in-out;

$primary_color: $primary-blue;
$primary_color_active: lighten($primary_color, 7%);
$primary_color_inactive: darken($primary_color, 7%);
$primary_color_light: lighten($primary_color, 49%);
$primary_background: #0490e9;

$primary_text_color: $primary-blue;
$primary_link_color: $primary-blue;
$primary_link_color_active: lighten($primary_link_color, 5%);
$primary_link2_color: #3fa9f5;
$primary_link2_color_active: #0b85dc;

$white-100: #f5f6f7;
$dark-100: #00131f;
$primary_item_page: #0490e9;

$border-radius: 8px;

/* Instant Win */

$iw_border_color: $neutral-200;
$iw_bigwin_font_size: 16px;
$iw_bigwin_font_weight: 700;
$iw_bigwin_letter_spacing: -0.16px;
$iw_bigprize_color: $neutral-600;
$iw_bigprize_number_color: $primary-50;
$iw_tab_title_color: $neutral-700;
$iw_show_all_color: $primary-500;

//badges
$badge_font_family: 'Inter';
$badge_font_weight: 500;
$badge_reveal_border_color: #b7e1ff;
$badge_reveal_color: $primary-600;
$badge_reveal_bg_color: #dfefff;
$badge_reveal_counter_background: $primary-600;
$badge_reveal_counter_color: $neutral-50;

$badge_win_border_color: rgba(226, 184, 76, 0.5);
$badge_win_color: $neutral-600;
$badge_win_background: linear-gradient(
    0deg,
    rgba(226, 184, 76, 0.35) 0%,
    rgba(226, 184, 76, 0.35) 100%
  ),
  $white;
$badge_win_background_active: linear-gradient(
    0deg,
    rgba(226, 184, 76, 0.55) 0%,
    rgba(226, 184, 76, 0.55) 100%
  ),
  $white;
$badge_win_counter_background: #e2b84c;
$badge_win_counter_color: $neutral-900;

$badge_loss_border_color: $neutral-300;
$badge_loss_border_color_active: $neutral-400;
$badge_loss_color: $neutral-600;
$badge_loss_background: $neutral-200;
$badge_loss_background_active: $neutral-300;
$badge_loss_counter_background: $neutral-400;
$badge_loss_counter_color: $neutral-900;
//button reveal
$button_reveal_background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(6, 29, 232, 0.5) 100%
  ),
  #0053f5;

$button_reveal_background_active: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(6, 29, 232, 0.25) 100%
  ),
  $primary-400;
$button_reveal_progressbar: $primary-950;
$button_reveal_color: $white;
$button_reveal_color_active: $white;
$button_reveal_font_family: $main-font;
$button_reveal_font_size: 16px;
$button_reveal_font_weight: 600;
$button_reveal_letter_spacing: -0.16px;

$button_font_family: $main-font;
$button_font_size: 16px;
$button_font_weight: 600;
$button_letter_spacing: -0.16px;
$button_background: #41d585;

//tickets
$reveal_border_color: #b7e1ff;
$reveal_ticket_font_family: $main-font-set;
$reveal_ticket_font_size: 10px;
$reveal_ticket_font_weight: 700;
$reveal_ticket_letter_spacing: -0.1px;
$reveal_ticket_bottom_color: $primary-500;
$reveal_ticket_circle_background: $neutral-50;
$reveal_ticket_bottom_background: #c6e7fb;
$reveal_ticket_border_color: #47b7ff;
$reveal_ticket_background: linear-gradient(
  to top right,
  #0490e9 -25%,
  #47b7ff 50%,
  #0490e9 100%
);
$win_ticket_background: #e2b84c;
$loss_ticket_background: $neutral-400;

// reveal dialog
$reveal_dialog_ticket_state_container_color: #eff8ff;
$reveal_dialog_ticket_state_font_family: $main-font;
$reveal_dialog_ticket_state_font_size1: 12px;
$reveal_dialog_ticket_state_font_size2: 16px;
$reveal_dialog_ticket_state_font_weight1: 700;
$reveal_dialog_ticket_state_font_weight2: 900;
$reveal_dialog_ticket_state_letter_spacing1: -0.12px;
$reveal_dialog_ticket_state_letter_spacing2: -0.16px;
$reveal_dialog_ticket_state_font_style: italic;
$reveal_dialog__ticket_state_box-shadow:
  1px 1px 2px 0px rgba(255, 255, 255, 0.24) inset,
  0px 1px 3px 0px rgba(10, 124, 255, 0.24),
  0px 2px 6px 0px rgba(10, 124, 255, 0.24),
  0px 4px 8px 0px rgba(10, 124, 255, 0.12);
$logo_spiner1: linear-gradient(90deg, $white, $primary-550);
$logo_spiner2: linear-gradient(90deg, $primary-550, $primary-550);
$suspense_text1: $primary-300;
$suspense_text2: $primary-550;
$suspense_text3: $primary-950;

// IW Product Page
$end-prize-background: conic-gradient(
  from 225deg at 43.6% 62.68%,
  #0490e9 0deg,
  #47b7ff 360deg
);
$end-prize-box-shadow:
  1px 1px 2px 0px rgba(255, 255, 255, 0.24) inset,
  0px 1px 3px 0px rgba(10, 124, 255, 0.24),
  0px 2px 6px 0px rgba(10, 124, 255, 0.24),
  0px 4px 8px 0px rgba(10, 124, 255, 0.12),
  0px 16px 32px -8px rgba(10, 124, 255, 0.48);
$end-prize-box-border: #0a7cff;
$end-prize-separator: #1f83f5;

$sticky-bar-btn-border-radius: 8px;

$iw-how-works-number-pading: 2px;

$product-quantity-border-radius: 11px;

$instant-scratch-container: $primary-550;
$instant-scratch-container-border: $primary-700;
$scratch-card-background: linear-gradient(
  243deg,
  #47b7ff 1.61%,
  $primary-500 98.83%
);
$scratch-card-icon-width-small: 33px;
$scratch-card-icon-width-medium: 40px;
$scratch-card-icon-width-large: 78px;

$order-details-alert-border: #b7e1ff;
$order-details-alert-text: #2fb0ff;
$order-details-instant-label: $primary-500;

$instant-flip-coin-icon-brightness: none;
$instant-flip-reveal-coin-id: #b7e1ff;
$instant-flip-sumarry-coin-id: $neutral-400;
$instant-flip-coin-break-point: 405px;
$instant-flip-coin-border-radius: 50%;
$instant-flip-coin-aspect-ratio: 1;
$instant-flip-coin-img-size: 77.3%;
$instant-flip-coin-img-fit: cover;
$instant-flip-coin-background-img: url('/assets/images/iw/coin-win.svg');
