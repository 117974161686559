@import 'styles/variables';

.insection-title {
  font-weight: 900;
  font-size: 28px;
  line-height: 32px;
  text-transform: uppercase;
  color: $dark-100;
  text-align: center;

  @media (min-width: $screen-lg-min) {
    font-weight: 800;
    font-size: 32px;
    line-height: 32px;
  }

  &--margin-y {
    margin: 32px 0;

    @media (min-width: $screen-lg-min) {
      margin-bottom: 64px;
    }
  }
}

.section-title {
  font-weight: 800;
  font-size: 28px;
  text-align: center;
  line-height: 32px;
  color: $dark-100;
  text-transform: uppercase;
  margin: 0 0 24px;

  @media (min-width: $screen-lg-min) {
    text-align: center;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 60px;
  }

  &--competition {
    //text-align: left;
    margin-bottom: 32px;

    @media (min-width: $screen-lg-min) {
      margin-bottom: 36px;
      //text-align: center;
    }
  }

  &--white {
    color: #fff;
  }

  &--cart {
    margin: 42px auto 0;
    letter-spacing: -1.6px;

    @media (max-width: $screen-sm-max) {
      margin: 31px auto 0;
      letter-spacing: normal;
    }
  }

  &--page {
    margin-top: 30px;
  }
}

.main-title {
  font-weight: 800;
  font-size: 28px;
  text-align: center;
  line-height: 32px;
  color: #808080;
  text-transform: uppercase;
  margin: 0 0 24px;

  @media (min-width: $screen-lg-min) {
    text-align: center;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 60px;
  }

  &--cart {
    margin: 42px auto 0;
    letter-spacing: -1.6px;

    @media (max-width: $screen-sm-max) {
      margin: 31px auto 0;
      letter-spacing: normal;
    }
  }

  &--account{
    font-family: Exo;
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: -0.2px;
    margin: 0;
  }
}
