.section {
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: $screen-sm-max) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  @media (max-width: $screen-xs-max) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &_gray { // .section_gray
    background-color: $section-gray;
  }

  &_white { // .section_white
    background-color: #fff;
  }

  &_dark { // .section_dark
    background-size: cover;
    background-position: center center;
    background-image: url('../../images/7days/winners/winners_bg.png');
    background-color: #222;
  }

  &_trustpilot { // .section_trustpilot
    padding-top: 50px;
    padding-bottom: 10px;
    text-align: center;
  }

  &_text-block { // .section_text-block
    padding-top: 50px;
    padding-bottom: 80px;

    @media (max-width:$screen-xs-max) {
      padding-top: 25px;
    }
  }

  &_faq { // .section_faq
    padding-top: 50px;
    padding-bottom: 50px;

    @media (max-width:$screen-xs-max) {
      padding-top: 25px;
    }
  }

  &_shopping-cart { // .section_shopping-cart
    padding-top: 0px;
    padding-bottom: 44px;

    @media (max-width: $screen-sm-max) {
      padding-bottom: 24px;
    }
  }

  &_last-chance { // .section_last-chance
    padding-top: 0px;
    padding-bottom: 0px;
  }

  &_shorter { // .section_shopping-cart
    padding-top: 10px;
    padding-bottom: 50px;
  }

  &_message-box { // .section_message-box
    padding-top: 50px;
    padding-bottom: 0;
  }

  &_flat-message-box { // .section_message-box
    padding-top: 0;
    padding-bottom: 0;
  }

  &_checkout { // .section_checkout
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &_stream-list { // .section_stream-list
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &_stream { // .section_stream
    padding-top: 25px;
    padding-bottom: 50px;
  }

  &_return-container { // .section_return-container
    padding-top: 0;
    padding-bottom: 50px;
  }

  &_reviews { // .section_reviews
    padding-bottom: 135px;

    @media (max-width: $screen-xs-max) {
      padding-bottom: 80px;
    }
  }

  &_post-list { // .section_post-list
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &_prize-delivery{ // .section_prize-delivery
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &_post { // .section_post
    padding-top: 50px;
    padding-bottom: 0;
  }

  &_account { // .section_account
    padding-top: 32px;
    padding-bottom: 58px;

    @media (min-width: $screen-sm-max) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

  }
  &_about-us { // .section_about-us
    padding-top: 20px;
    padding-bottom: 0;
  }
  &_refund { // .section_refund
    padding-top: 0;
    padding-bottom: 0;
  }
  &_team{ // .section_team
    padding-top: 48px;
    padding-bottom: 48px;
    @media (max-width:$screen-xs-max) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  &_member{ // .section_member
    padding-top: 90px;
    padding-bottom: 90px;
    @media (max-width:$screen-xs-max) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  &_product { // .section_product
    padding-top: 0;
    padding-bottom: 0;
  }

  &_faq-product { // .section_faq-product
    padding-top: 0;
  }

  &__header { // .section__header
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 36px;
    font-weight: 700;
    text-align: center;

    @media (max-width: $screen-xs-max) {
      font-size: 30px;
    }

    &_white { // .section__header_white
      color: #fff;
    }
  }

  &__wrapper { // .section__wrapper
    &_center { // .section__wrapper_center
      display: flex;
      justify-content: center;
    }
  }

  &__image { // .section__image
    max-width: 100%;

    &_info-bar { // .section__image_info-bar
      width: 100%;
      max-width: 470px;
    }
  }

  &__text { // .section__text
    &_faq { // .section__text_faq
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 17px;
      line-height: 26px;
      text-align: center;
      color: #808080;
    }

    &_faq-link { // .section__text_faq-link
      color: $blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__view-all-link { // .section__view-all-link
    margin-top: 30px;
    text-align: center;
  }
}
