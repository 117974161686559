/* Globals */
@use '@angular/material' as mat;
@use './assets/styles/palette.scss' as palette;
@include mat.core();

$my-primary: mat.define-palette(palette.$primary, 500);
$my-accent: mat.define-palette(palette.$accent, 300, A100, A200);

$seven-days-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent
    ),
    typography: mat.define-typography-config(),
    density: 0
  )
);

@include mat.all-component-themes($seven-days-theme);

@import './assets/styles/grid_override';
@import 'bootstrap-scss/bootstrap-grid.scss';

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/lazy';

@import 'bootstrap-scss/mixins/deprecate';
@import 'bootstrap-scss/mixins/hover';
@import 'bootstrap-scss/mixins/text-emphasis';
@import 'bootstrap-scss/mixins/text-hide';
@import 'bootstrap-scss/mixins/text-truncate';
@import 'bootstrap-scss/mixins/visibility';

@import 'bootstrap-scss/utilities/align';
@import 'bootstrap-scss/utilities/text';
@import 'bootstrap-scss/utilities/visibility';

@import '@ng-select/ng-select/themes/default.theme.css';
@import 'ngx-sharebuttons/themes/default.scss';
@import 'video.js/dist/video-js.min.css';
@import '@ctrl/ngx-emoji-mart/picker';

@import './assets/styles/fonts';
@import './assets/styles/app-pop-up';
@import './assets/styles/blocks/flag';

@import './styles-p7d';
@import './assets/styles/blocks/button.scss';

.clearfix {
  *zoom: 1;
  &:after {
    content: '';
    display: block;
    clear: both;
    height: 0;
  }
}

.posr {
  position: relative;
}

@each $breakpoint, $windowMaxWidth in $grid-breakpoints {
  $containerWidth: map-get($container-max-widths, $breakpoint);

  @if $containerWidth {
    .container-half {
      @media (min-width: $windowMaxWidth) {
        max-width: calc($containerWidth / 2);
      }
    }

    @each $breakpoint2, $windowMaxWidth2 in $grid-breakpoints {
      @if $windowMaxWidth2 >= $windowMaxWidth {
        .container-max-#{$breakpoint2} {
          @media (min-width: $windowMaxWidth) {
            max-width: $containerWidth;
            margin: 0 auto;
          }
        }
      }

      @if $windowMaxWidth2 <= $windowMaxWidth {
        .container-half-#{$breakpoint2} {
          @media (min-width: $windowMaxWidth) {
            max-width: calc($containerWidth / 2);
          }
        }
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f6f7f9 inset !important;
}

.container-wide {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@each $breakpoint, $windowMaxWidth in $grid-breakpoints {
  $containerWidth: map-get($container-max-widths2, $breakpoint);

  @if $containerWidth {
    .container-wide {
      @media (min-width: $windowMaxWidth) {
        max-width: $containerWidth;
      }
    }

    @each $breakpoint2, $windowMaxWidth2 in $grid-breakpoints {
      @if $windowMaxWidth2 <= $windowMaxWidth {
        .container-wide-#{$breakpoint2} {
          @media (min-width: $windowMaxWidth) {
            max-width: $containerWidth;
          }
        }
      }
    }
  }
}

/* material style start */

.mat-mdc-slide-toggle {
  --mdc-switch-track-width: 44px !important;
  --mdc-switch-track-shape: 16px !important;
  --mdc-switch-track-height: 24px !important;
  --mdc-switch-handle-width: 24px !important;

  &.mat-accent {
    --mdc-switch-selected-handle-color: #ffffff !important;
    --mdc-switch-selected-focus-handle-color: #ffffff !important;
    --mdc-switch-selected-hover-handle-color: #ffffff !important;
    --mdc-switch-selected-pressed-handle-color: #ffffff !important;
    --mdc-switch-selected-icon-color: #40d586 !important;
    --mdc-switch-unselected-focus-handle-color: #ffffff !important;
    --mdc-switch-unselected-focus-state-layer-color: #424242 !important;
    --mdc-switch-unselected-focus-track-color: #0000001a !important;
    --mdc-switch-unselected-handle-color: #ffffff !important;
    --mdc-switch-unselected-hover-handle-color: #ffffff !important;
    --mdc-switch-unselected-hover-state-layer-color: #424242 !important;
    --mdc-switch-unselected-hover-track-color: #0000001a !important;
    --mdc-switch-unselected-icon-color: #0000001a !important;
    --mdc-switch-unselected-pressed-handle-color: #ffffff !important;
    --mdc-switch-unselected-pressed-state-layer-color: #424242 !important;
    --mdc-switch-unselected-pressed-track-color: #0000001a !important;
    --mdc-switch-unselected-track-color: #0000001a !important;
    --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38) !important;
  }

  .mdc-switch__handle-track {
    left: 2px;
  }
}

.cdk-overlay-container {
  .cdk-overlay-backdrop {
    &.app-overlay-backdrop {
      background-color: rgba(62, 63, 75, 0.7);
    }
    &.reveal-tickets-backdrop,
    &.big-win-backdrop {
      background-color: rgba(5, 42, 76, 0.7);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
    }
    &.p7d-overlay-gallery-backdrop {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  .cdk-overlay-pane {
    max-width: calc(100vw - 20px) !important;

    .mat-mdc-dialog-container {
      width: calc(100vw - 20px);
      font-family: Exo, sans-serif;

      .mdc-dialog__surface {
        padding: 0;
        border-radius: 15px;
        box-shadow: 0 3px 41px rgb(0 0 0 / 15%);
      }

      .link {
        color: $primary_link2_color;
        transition: color 0.3s ease;
        text-decoration: underline;
      }

      .dialog-close {
        position: absolute;
        text-decoration: none;
        top: 8px;
        right: 8px;
        color: #d3d3d3;
        transition:
          color 0.25s ease-in-out,
          background-color 0.15s ease-in-out;
        display: block;
        padding: 4px;
        border-radius: 3px;
        width: 33px;
        height: 33px;
        z-index: 100;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:hover,
        &:active {
          color: darken(#d3d3d3, 15%);
          background-color: #fafafa;
          text-decoration: none;
        }

        &:focus {
          color: darken(#d3d3d3, 15%);
          text-decoration: none;
        }

        &:hover:active {
          background-color: darken(#fafafa, 10%);
        }
      }
    }
  }
}

.mat-mdc-menu-panel {
  min-height: 44px !important;

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
    & > div {
      display: flex;
      flex-direction: column;

      button {
        border: none;
        background-color: #ffffff;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 5px 10px 10px;
        transition: all 0.25s ease;
        color: #646a70;

        &:hover {
          background-color: #f1f1f1;
          transition: all 0.25s ease;
        }

        svg-icon {
          width: 24px;
          margin-right: 5px;

          svg {
            fill: #646a70;
          }
        }
      }
    }
  }
}

.mat-mdc-slider {
  width: 100% !important;
  padding: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;

  --mdc-slider-inactive-track-height: 5px !important;
  --mdc-slider-active-track-height: 5px !important;
  --mdc-slider-handle-color: #ffffff !important;
  --mdc-slider-focus-handle-color: #ffffff !important;
  --mdc-slider-hover-handle-color: #ffffff !important;
  --mdc-slider-active-track-color: #0490e9;
  --mdc-slider-inactive-track-color: #e9ebed !important;

  --mdc-slider-label-container-color: #0490e9 !important;
  --mat-slider-value-indicator-width: 60px !important;
  --mat-slider-value-indicator-height: 25px !important;
  --mat-slider-value-indicator-padding: 0 !important;
  --mat-slider-value-indicator-opacity: 1 !important;
  --mat-slider-value-indicator-border-radius: 5px !important;
  --mat-slider-value-indicator-container-transform: translateX(
    -10px
  ) !important;

  --mdc-slider-label-label-text-font: 'Exo', sans-serif !important;
  --mdc-slider-label-label-text-size: 16px !important;
  --mdc-slider-label-label-text-line-height: 22px !important;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 600 !important;

  .mat-mdc-focus-indicator {
    display: none !important;
  }

  &.max {
    .mdc-slider__value-indicator-container {
      transform: translateX(-85%) !important;
    }

    .mdc-slider__value-indicator {
      &:before {
        left: 51px !important;
      }
    }
  }

  .mdc-slider {
    &__thumb-knob {
      border-color: var(--mdc-slider-active-track-color) !important;
      border-width: 2px !important;
    }

    &__value-indicator-container {
      bottom: 39px !important;
    }

    &__value-indicator {
      transform: scale(1) !important;

      &:after {
        content: none;
      }

      &:before {
        transition: border-top-color 0.2s !important;
        bottom: -5px !important;
        left: 10px !important;
        width: 2px !important;
        height: 5px !important;
        border: none !important;
        background-color: var(--mdc-slider-active-track-color);
      }
    }

    &__value-indicator-text {
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(0) !important;

      &:after {
        content: '';
        display: inline-block;
        width: 12px;
        height: 16px;
        background: url(/assets/svg-icons/ticket-small.svg);
        background-size: cover;
        background-position: center;
        margin-left: 5px;
        position: relative;
      }
    }

    &__track {
      &::before {
        content: '';
        position: absolute;
        left: 1px;
        width: 1px;
        height: 5.5px;
        bottom: -5.5px;
        background: #6e7a84;
      }

      &::after {
        content: '';
        position: absolute;
        right: 1px;
        width: 1px;
        height: 5.5px;
        bottom: -5.5px;
        background: #6e7a84;
        top: unset;
      }

      &--inactive {
        opacity: 1 !important;
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.select-wrap {
  position: relative;
  width: 100%;
  @media (min-width: $screen-lg-min) {
    flex: 1 0 350px;
  }
  &::after {
    content: '';
    width: 8px;
    height: 8px;
    background-image: url("data:image/svg+xml,%3Csvg fill='%23495057' height='800px' width='800px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 330 330' xml:space='preserve'%3E%3Cpath id='XMLID_225_' d='M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z'/%3E%3C/svg%3E");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  select {
    width: 100%;
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
