.container {
  &_faq { // .container_faq
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
  }

  &_product { // .container_faq-product
    max-width: 828px;
    margin-left: auto;
    margin-right: auto;
  }

  &_cart { // .container_cart
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $screen-xs-max) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &_account { // .container_account
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }

  &_post { // .container_post
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
}
