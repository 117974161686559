@import 'styles/variables';

.main-title {
  margin: 0;
  padding: 42px 15px;
  font-family: $secondary-font;
  font-size: 40px;
  //background-color: $blue;
  //color: #fff;
  background-color: #f6f7f9;
  color: #808080;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  @media (max-width:$screen-sm-max) {
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 32px;
  }

  @media (max-width:$screen-xs-max) {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 24px;
  }

  &_dark-blue { // .main-title_dark-blue
    background-color: $dark-blue;
    text-shadow: 4px 4px 13px rgba(#000, .31);
  }
  &_light-blue { // .main-title_light-blue
    background-color: #40a8f5;
    color: #ffffff !important;
  }

  &_light-gray { // .main-title_light-gray
    background-color: #f6f7f9;
    color: #808080;
  }

  &_small { // .main-title_small
    padding-top: 17px;
    padding-bottom: 17px;
  }
}
