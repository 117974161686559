flag {
  display: inline-block;

  svg {
    display: inline-block;
    height: 0.75em;
    width: auto;
  }
}

.flag {
  display: inline-block;
  height: 0.75em;
  width: auto;
}
