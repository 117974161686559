.alert-list {
  &__item { // .alert-list__item
    margin-bottom: 25px;

    &--short {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }

    &--lighter-font {
      p {
        font-size: 16px;
        font-weight: 300;

        a {
          font-size: 16px;
          font-weight: 300;
        }
      }
    }

    &--smaller-font {
      p {
        font-size: 16px;

        a {
          font-size: 16px;
        }
      }
    }
  }

  &--margin-minus10px {
    margin-left: -10px;
    margin-right: -10px;
  }
}
