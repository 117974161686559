@import 'styles/variables';

.button {
  position: relative;
  display: inline-block;
  padding: 15px 26px;
  border: 0;
  border-radius: 4px;
  font-family: $main-font;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  text-decoration: none;
  outline: none;

  @media (max-width: $screen-xs-max) {
    font-size: 18px;
    padding: 11px 23px;
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    filter: saturate(90%);
  }

  &:hover,
  &:focus {
    box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
    filter: brightness(1.05);
  }

  &:focus {
    outline: none;
  }

  // .button_white
  &_white {
    background-color: #fff;
    color: $dark-blue;

    .button__arrow {
      color: $blue;
    }

    &:hover {
      color: #0b85dc;
    }
  }

  // .button_border
  &_border {
    border: 2px solid $primary_color;
    background: transparent;
    width: 100%;
    border-radius: 10px;
    color: $primary_color;
    padding: 7px 0;

    &:hover {
      background: $primary_color;
      color: white;
    }
    &:focus {
      filter: none;
    }
  }

  &_cancel {
    color: #5f6368;
    border: solid 1px #c4c9ce;
    background-color: transparent;
  }

  // .button_border-grey
  &_border-grey {
    border: 2px solid #c4c9ce;
    background: transparent;
    width: 100%;
    border-radius: 10px;
    color: #5f6368;
    padding: 7px 0;

    &:hover {
      background: #c4c9ce;
      color: #5f6368;
    }
    &:focus {
      filter: none;
    }
  }

  // .button_icon
  &_icon {
    svg-icon {
      margin-left: 8px;
      height: 26px;
      width: 26px;

      svg {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  // .button_blue
  &_blue {
    background-color: $blue;
    color: #fff;

    &:hover {
      //background-color: #1b99f3;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($primary_link2_color, 0.5);
    }
  }

  // .button_dark-blue
  &_dark-blue {
    background-color: $dark-blue;
    color: #fff;

    &:hover {
      //background-color: #0c2d44;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(#124264, 0.5);
    }

    &:disabled {
      opacity: 1;
      color: #858585;
      background-color: #efefef;
      cursor: not-allowed;
    }
  }

  // .button_green
  &_green {
    background-color: $green-dark;
    color: #fff;

    &:hover {
      //background-color: #2bc473;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgb($primary_link2_color, 0.25);
    }
  }

  // .button_red
  &_red {
    background-color: $red;
    color: #fff;

    &:hover {
      //background-color: #c82333;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($red, 0.5);
    }
  }

  // .button_red
  &_danger {
    background-color: transparent;
    color: $red;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  // .button_transparent
  &_transparent {
    background-color: rgba(#fff, 0);
    color: $blue;

    &:hover {
      color: #0b85dc;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgb($primary_link2_color, 0.25);
    }
  }

  // .button_no-shadow
  &_no-shadow {
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  &_tiny {
    font-size: 14px;
    padding: 7px 7px 5px;
    font-weight: 500;
    line-height: 1;
    min-width: 76px;
    box-sizing: border-box;
    outline: none;

    @media (max-width: $screen-xs-max) {
      font-size: 12px;
      padding: 5px 7px 5px;
      min-width: 70px;
    }
  }

  // .button_arrow
  &_arrow {
    padding-right: 75px;

    @media (max-width: $screen-xs-max) {
      padding-right: 50px;
    }
  }

  // .button_cart
  &_cart {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  // .button--smaller
  &--smaller {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;

    @media (max-width: $screen-xs-max) {
      font-size: 14px;
    }
  }

  &_disabled {
    pointer-events: none;
    background: #999;
  }

  &_inactive {
    background: #999;
    cursor: default;

    &:hover {
      //background: #999;
    }
  }

  // .button__arrow
  &__arrow {
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
  }

  &__spinner {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 22px;
    height: 22px;
    animation: rotate 1000ms linear infinite;
  }
}
