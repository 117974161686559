.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 25px;
  border-width: 1px;
  border-style: solid;

  &_column {
    flex-direction: column;
  }

  &_blue { // .alert_blue
    background-color: #cce9fe;
    border-color: #b8e0fe;
  }

  &_light-blue {
    background: #ecf9ff;
    border-color: #ecf9ff;
  }

  &_green { // .alert_green
    background-color: #d9f7e7;
    border-color: #caf3dd;
  }

  &_warn { // .alert_warn
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &_error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    font-weight: 400;
    margin-bottom: 50px;
    flex-direction: column;
  }

  &_text-left { // .alert_text-left
    text-align: left;
    justify-content: flex-start;
  }

  &_mt {
    margin-top: 50px;
  }

  &_mb {
    margin-bottom: 30px;
  }

  &__text,
  &__link { // .alert__text
    margin: 0;
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
    text-align: center;

    .alert_blue &, .alert_light-blue & {
      color: $dark-blue;
    }

    .alert_green & {
      color: #216f46;
    }

    &_d-blue {
      color: $dark-blue;
    }
  }

  &__link { // .alert__link
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    .alert_blue &, .alert_light-blue & {
      color: $blue;
    }

    .alert_warn & {
      color: $blue;
    }

    &_right {
      margin-left: auto;
    }

    &_green {
      color: #216f46;
    }

    &_d-blue {
      color: $dark-blue;
    }

    &_dark {
      color: #335577 !important;
      text-decoration: underline;
      font-weight: 600;
    }
  }

  &__action { // .alert__action
    margin-left: auto;
    padding: 0;
    border-width: 0;
    background-color: rgba(#fff, 0);
    cursor: pointer;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      .alert_green & {
        color: $green-dark;
      }
    }

    &_d-blue {
      color: $dark-blue;
    }
  }
}
