@import 'styles/fonts';
@import 'styles/variables';

.website-p7d, .website-p7ds {
  //padding-top: 100px;
  font-family: $main-font-set;
  font-weight: 400;
  font-size: 18px;

  @media (max-width: $screen-md-max) {
    //padding-top: 62px;
  }

  table, tr, td, th, tbody, thead, tfoot, img, label, fieldset {
    font-family: inherit;
  }

  input, select, option, textarea {
    font-family: $main-font-set;
  }

  .min90height {
    min-height: calc(100vh - 400px);
  }

  .spinner-button {
    height: 16px;
    width: 16px;
    color: white;

    &_color-parent {
      color: inherit;
    }
  }

  .top-message {
    text-align: center;
    padding: 25px;
    color: white;
    font-weight: 700;
    background: #ff0000; /** FF4500 **/
  }

  .sharebuttons-p7d {
    &.sb-group {
      justify-content: center;
      gap: 5px;

      @media (min-width: $screen-sm-min) {
        justify-content: flex-start;

      }
    }

    .sb {
      &-button {
        margin: 0;
        width: calc(100% / 3 - 10px / 3);
        max-width: 115px;

        @media (min-width: $screen-sm-min) {
          margin-right: 4px;
          width: auto;
          max-width: none;
        }
      }

      &-whatsapp {
        background-color: #33D16B !important;
      }

      &-messenger {
        background-color: #1683FB !important;
      }

      &-copy {
        background-color: #617D8A !important;
      }
    }

    .sb-wrapper {
      font-size: 11px !important;
      padding: 8px 10px 8px 8px;
      width: 100%;
      margin: 0;

      @media (min-width: $screen-md-min) {
        width: auto
      }

      .sb-text {
        font-family: $main-font-set;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  .sharebuttons-p7d--old {
    &.sb-group {
      @media (max-width: $screen-sm-max) {
        justify-content: center;
      }
    }

    .sb-button {
      margin: 3px;
    }

    .sb-wrapper {
      font-size: 11px !important;
    }

  }

  @import 'styles/blocks/button';
  @import 'styles/blocks/section';
  @import 'styles/blocks/container';
  @import 'styles/blocks/row';
  @import 'styles/blocks/main-title';
  @import 'styles/blocks/alert';
  @import 'styles/blocks/alert-list';
  @import 'styles/blocks/loading';
  @import "styles/blocks/form";
  @import "styles/blocks/section-title";
}
