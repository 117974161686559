$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        hd: 1600px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
);

$container-max-widths2: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  hd: 1400px
);
