@import 'styles/variables';

.simple-input {
  display: block;
  margin-bottom: 16px;

  &__row{
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  &__col {
    flex: 1 1 min-content;

    &--phone-country{
      flex: 0 0 auto;
    }
  }

  &_error {
    & .simple-input__hint {
      color: #f44;
    }
  }

  &__label { // .simple-input__label
    display: block;
    margin-bottom: 8px;
    font-size: 17px;
    line-height: 26px;
    font-weight: 700;
    color: $dark-blue;
  }

  &__input { // .simple-input__input
    display: block;
    width: 100%;
    padding: 10px 12px;
    background-color: #f6f7f9;
    border: 1px solid #d8dadd;
    font-size: 16px;
    line-height: 24px;
    color: #495057;
    transition: all 0.3s;
    border-radius: 0;

    &:focus {
      background-color: #fff;
      border-color: #b8dffb;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgb(63 169 245 / 25%);
    }
  }

  &__hint { // .simple-input__hint
    color: #808080;
    font-size: 17px;
    font-weight: 400;
    font-style: italic;
  }

  &__required-asterisk { // .simple-input__required-asterisk
    margin-right: 5px;
    cursor: help;
  }
}

.simple-checkbox {
  display: block;
  margin-bottom: 16px;

  &_error {
    & .simple-checkbox__hint {
      color: #f44;
    }
  }

  &__label { // .simple-checkbox__label
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.5;
    cursor: pointer;
    color: #808080;
  }


  &__hint { // .simple-checkbox__hint
    color: #808080;
    font-size: 17px;
    font-weight: 400;
    font-style: italic;
    display: block;
  }

  &__required-asterisk { // .simple-checkbox__required-asterisk
    margin-right: 5px;
    cursor: help;
  }
}

.simple-radio {
  display: block;
  margin-bottom: 16px;


  &__label { // .simple-radio__label
    margin-left: 8px;
    font-size: 16px;
    line-height: 1.5;
    cursor: pointer;
    color: #808080;
  }

}


.simple-select {
  display: block;
  width: 100%;
  padding: 10px 12px;
  background-color: #f6f7f9;
  border: 1px solid #d8dadd;
  font-size: 16px;
  line-height: 24px;
  color: #495057;
  transition: all 0.3s;

  &:focus {
    background-color: #fff;
    border-color: #b8dffb;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(63 169 245 / 25%);
  }

  &__box_error {
    & .simple-select__hint {
      color: #f44;
    }
  }

  &__box { // .simple-select__box
    display: block;
    margin-bottom: 16px;
  }

  &__label { // .simple-select__label
    display: block;
    margin-bottom: 8px;
    font-size: 17px;
    line-height: 26px;
    font-weight: 700;
    color: $dark-blue;
  }

  &__hint { // .simple-select__hint
    color: #808080;
    font-size: 17px;
    font-weight: 400;
    font-style: italic;
    margin-right: 5px;
    cursor: help;
  }

  &__required-asterisk { // .simple-select__required-asterisk
    margin-right: 5px;
    cursor: help;
  }
}

input.ng-invalid[type="checkbox"] {
  box-shadow: 0 0 1px 1px #FF0000;
}

