@import './variables';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/roboto-v27-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/roboto-v27-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/roboto-v27-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/roboto-v27-latin-300.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/roboto-v27-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/roboto-v27-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/roboto-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/roboto-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/roboto-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/roboto-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/roboto-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/roboto-v27-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/roboto-v27-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/roboto-v27-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/roboto-v27-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/roboto-v27-latin-500.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/roboto-v27-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/roboto-v27-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/roboto-v27-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/roboto-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/roboto-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/roboto-v27-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/roboto-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/roboto-v27-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* barlow-condensed-600 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/BarlowCondensed-SemiBold.ttf');
}

/* barlow-condensed-800 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/fonts/barlow-condensed-v5-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/barlow-condensed-v5-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/barlow-condensed-v5-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/barlow-condensed-v5-latin-800.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/barlow-condensed-v5-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/barlow-condensed-v5-latin-800.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}

/* Impact */
@font-face {
  font-family: 'Impact';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/IMPACT.TTF'); /* IE9 Compat Modes */
}

/* Exo thin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/fonts/exo-font/static/Exo-Thin.ttf'); /* IE9 Compat Modes */
}

/* Exo extra light */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/exo-font/static/Exo-ExtraLight.ttf'); /* IE9 Compat Modes */
}

/* Exo light */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/exo-font/static/Exo-Light.ttf'); /* IE9 Compat Modes */
}

/* Exo regular */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/exo-font/static/Exo-Regular.ttf'); /* IE9 Compat Modes */
}

// Exo 500
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/exo-font/static/Exo-Medium.ttf'); /* IE9 Compat Modes */
}

/* Exo 600 */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/exo-font/static/Exo-SemiBold.ttf'); /* IE9 Compat Modes */
}

/* Exo 700 */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/exo-font/static/Exo-Bold.ttf'); /* IE9 Compat Modes */
}


/* Exo 800 */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/fonts/exo-font/static/Exo-ExtraBold.ttf'); /* IE9 Compat Modes */
}

/* Exo 900 */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/exo-font/static/Exo-Black.ttf'); /* IE9 Compat Modes */
}


