.app-pop-up {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 99999;
  text-align: center;
  display: flex;
  align-items: center;
  transition: background 0.2s 0.3s;
  display: none;
  font-size: 1.6rem;
  color: #333;
  text-transform: none;
  cursor: text; /* fallback */
  cursor: initial;
  
  .age-verify-dialog {
    width: 95%;
    max-width: 400px;
    text-align: left;
  }
  
  .age-verify-dialog h4 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
  }

  .popup-wrap {
    max-width: 90vw;
    max-height: 90vh;
    margin: 0 auto;
    background: #fff;
    display: inline-block;
    position: relative;
    transform: scale(0.85);
    opacity: 0;
    transition: transform 0.3s 0s, opacity 0.3s 0s;
    z-index: 1;

    .overflow {
      overflow: auto;
      max-height: calc(90vh - 4rem);

      > * {
        padding: 4rem 2rem 2rem;
        display: block;
      }
    }

    .close {
      position: absolute;
      font-size: 2.1rem;
      top: 10px;
      right: 10px;
      width: 25px;
      height: 25px;
      line-height: 19px;
      border: 2px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      background: #212121;
      color: #fff;
    }
  }

  .box-bg-close {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.show {
    display: flex;

    .popup-wrap {
      opacity: 1;
    }
  }

  &.inflate {
    background: rgba(0, 0, 0, 0.8);
    transition: background 0.2s 0s;

    .popup-wrap {
      transform: scale(1);
      opacity: 1;
      transition: transform 0.3s 0.2s, opacity 0.3s 0.2s;
    }
  }
}
