.loading {
  color: $blue;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 385px);

  &__icon {
    width: 60px;
    height: 60px;
    animation: rotate 1000ms linear infinite;

    &--tiny{
      width: 16px;
      height: 16px;
    }
  }
}
